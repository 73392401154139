import LucidMascot from "@/assets/images/lucid-mascot.png";
import event2024 from "@/assets/images/event-banner-2024.webp";
import Subscribe from "@/components/Subscribe";
import Layout from "@/components/elements/Layout";
import CarouselLatest from "@/components/parts/CarouselLatest";
import CarouselSpotlight from "@/components/parts/CarouselSpotlight";
import LatestNews from "@/components/parts/LatestNews";
import Schema from "@/components/schema/Schema";
import { Button } from "@/components/ui/button";
import bannerBackground from "@/assets/images/banner-background.png";
import {
  getContentsByContentTypeName,
  getContentsHomePage,
  getTopics,
  getTrendingContents,
  getBannerContents,
} from "@/services/api";

import { searchStartup, getStartupList } from "@/services/api/Startup";
import { searchInvestor } from "@/services/api/VentureCapital";
import { CONTENT_TYPES } from "@/utils/content";
import { ArrowRight, Check } from "@phosphor-icons/react";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useAtom } from "jotai";
import { purchaseStartupPopup, loginPopup } from "@/Atoms";
import useAuth from "@/hooks/auth";
import CarouselTopVideo from "@/components/parts/CarouselTopVideo";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";

const CarouselReport = dynamic(
  () => import("@/components/parts/CarouselReport"),
  {
    ssr: false,
  }
);
const CarouselVideo = dynamic(
  () => import("@/components/parts/CarouselVideo"),
  {
    ssr: false,
  }
);
const CarouselInfobyte = dynamic(
  () => import("@/components/parts/CarouselInfobyte"),
  {
    ssr: false,
  }
);
const CarouselArticle = dynamic(
  () => import("@/components/parts/CarouselArticle"),
  {
    ssr: false,
  }
);
const CarouselTrending = dynamic(
  () => import("@/components/parts/CarouselTrending"),
  {
    ssr: false,
  }
);
const CarouselExpert = dynamic(
  () => import("@/components/parts/CarouselExpert"),
  {
    ssr: false,
  }
);

const spreadOpinionsByAuthor = (opinions) => {
  // Step 1: Group opinions by author
  const opinionsByAuthor = {};
  opinions.forEach((opinion) => {
    const authorId = opinion.author.id;
    if (!opinionsByAuthor[authorId]) {
      opinionsByAuthor[authorId] = [];
    }
    opinionsByAuthor[authorId].push(opinion);
  });

  // Step 2: Create a list to spread opinions
  const spreadOpinions = [];
  let roundRobinArray = Object.values(opinionsByAuthor);
  while (roundRobinArray.length > 0) {
    roundRobinArray = roundRobinArray.filter((opinions) => opinions.length > 0);
    roundRobinArray.forEach((opinions) => {
      if (opinions.length > 0) {
        spreadOpinions.push(opinions.shift());
      }
    });
  }

  return spreadOpinions;
};

const Home = ({
  special_reports,
  latest_videos,
  latest_infobytes,
  latest_articles,
  latest_spotlights,
  trendings,
  latest_news,
  loaded,
  latest_opinions: _latest_opinions,
  banners,
}) => {
  const [startups, setStartups] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [latest_opinions, setLatestOpinions] = useState([]);
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openPurchaseStartupPopup, setOpenPurchaseStartupPopup] =
    useAtom(purchaseStartupPopup);

  const router = useRouter();
  const [loading, setLoading] = useState(true);

  const startupCounter = useRef();
  const investorCounter = useRef();
  const { user } = useAuth();

  useEffect(() => {
    if (loaded) {
      setLoading(false);
      setLatestOpinions(spreadOpinionsByAuthor(_latest_opinions));
    }
  }, [loaded]);

  const structuredData = [
    {
      "@context": "http://schema.org/",
      "@type": "Organization",
      name: "Lucidity Insights",
      foundingDate: "2022-01",
      url: "https://lucidityinsights.com/",
      logo: "https://lucidityinsights.com/_next/static/media/nav-logo.06ce4405.svg",
      description:
        "Lucidity Insights is a leading platform providing insights on startups, venture capital, and investments across the MENAPT region, Saudi Arabia, UAE, and Africa startup ecosystems.",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Office 235, Building 10, Dubai Media City",
        addressLocality: "Dubai",
        addressRegion: "AE",
        postalCode: "00000",
        addressCountry: "United Arab Emirates",
      },
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "customer support",
        email: "support@lucidityinsights.com",
        url: "https://lucidityinsights.com/",
      },
      sameAs: [
        "https://lucidityinsights.com/startups/lucidity-insights-3275",
        "https://twitter.com/lucidityinsight",
        "https://www.linkedin.com/company/lucidity-insights/",
        "https://www.instagram.com/lucidityinsights/",
        "https://www.youtube.com/@lucidityinsights",
      ],
    },
    {
      "@context": "http://schema.org/",
      "@type": "WebSite",
      name: "Lucidity Insights",
      url: "https://lucidityinsights.com/",
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://lucidityinsights.com/search/results?keyword={search_term_string}",
        "query-input": "required name=search_term_string",
      },
    },
  ];

  const getStartups = async () => {
    const res = await getStartupList({
      params: {
        trending: true,
      },
    });
    const data = res.data.data;
    setStartups(data);
  };

  const getInvestors = async () => {
    const res = await searchInvestor({
      params: {
        keyword: "",
        per_page: 5,
      },
    });
    const data = res.data.data;
    setInvestors(data);
  };

  useEffect(() => {
    getStartups();
    getInvestors();
  }, []);

  useEffect(() => {
    gsap.from(startupCounter.current, {
      textContent: 0,
      duration: 7,
      ease: "power4.inOut",
      snap: { textContent: 1 },
      stagger: 1,
    });

    gsap.from(investorCounter.current, {
      textContent: 0,
      duration: 5,
      ease: "power4.inOut",
      snap: { textContent: 1 },
      stagger: 1,
    });
  }, []);

  if (typeof window !== "undefined") {
    // // We're on the client and GSAP should be defined globally
    // gsap.to(".box", {
    //   y: 100,
    //   stagger: 0.1, // 0.1 seconds between when each ".box" element starts animating
    // });
  }

  // if (loading) {
  //   return (
  //     <div className='flex items-center justify-center min-h-screen space-x-4 bg-background'>
  //       <div className='flex-shrink-0 relative h-20 aspect-[199/320]'>
  //         <Image
  //           src={LucidMascot.src}
  //           alt=''
  //           layout={"fill"}
  //           objectFit={"cover"}
  //         />
  //       </div>
  //       <span className='text-lg font-bold text-white'>Loading...</span>
  //     </div>
  //   );
  // }

  const handlePurchaseStartup = () => {
    if (user) {
      setOpenPurchaseStartupPopup(true);
    } else {
      setOpenLoginPopup(true);
      localStorage.setItem("nextStep", "purchaseStartupPopup");
    }
  };

  return (
    <>
      <Schema data={structuredData} />
      <Layout
        title={`Lucidity Insights: Powering MENA’s Tech Ecosystems`}
        description={`Discover MENA Fintech insights with Lucidity Insights. Access in-depth articles, PDF reports, videos, & tools to stay informed `}
        meta_image='https://cdn.lucidityinsights.com/thumbnaildefault.jpg'
      >
        <main>
          {/* <div className='sticky z-20 items-center justify-center hidden gap-2 text-sm shadow-md text-background lg:flex top-28 h-9 bg-gradient-to-tr from-yellow-1 to-red-1'>
            <b className='font-extrabold uppercase'>
              Limited Offer! (15 - 18 Oct)
            </b>
            <span>-</span>
            <span className='font-medium'>
              Sign up during GITEX week and receive a{" "}
              <b className='font-extrabold'>50% discount</b> on our Startup
              Registration package
            </span>
            <Link legacyBehavior href='/gitexqr' passHref>
              <a className='inline-flex px-2.5 py-1.5 text-xs font-semibold text-background bg-white rounded shadow-md'>
                Reserve your Spot
              </a>
            </Link>
          </div> */}
          <CarouselLatest banners={banners} />
          <div className='container pb-10 lg:pb-16'>
            {/* <Link
              href={"https://forms.gle/EuDUtzQeKsmV5AVv8"}
              target='_blank'
              className='relative aspect-[1667/261] w-full'
            >
              <Image src={event2024} alt='' className='' />
            </Link> */}
            {/* <Link
              href='/gitexqr'
              className='relative w-full h-full aspect-[1201/251]'
            >
              <Image
                src={event2024}
                alt=''
                className='absolute inset-0 block object-cover w-full h-full'
              />
            </Link> */}
          </div>
          {/* <Link legacyBehavior href='/gitexqr' passHref>
            <a className='block kpx-4 lg:hidden md:px-20 lg:max-w-[970px] mx-auto my-12 lg:my-16'>
              <div className='relative aspect-[97/25] w-full'>
                <Image
                  src={gitexEvent}
                  alt=''
                  layout={"fill"}
                  objectFit={"cover"}
                />
              </div>
            </a>
          </Link> */}

          <div className='container relative mb-20'>
            <div className='relative items-center justify-between px-5 py-6 lg:px-16 lg:py-10 lg:flex bg-gradient-to-tr from-yellow-1 to-red-1'>
              <div className='absolute inset-y-0 right-0 w-[45%]'>
                <Image
                  src={bannerBackground}
                  alt={""}
                  layout={"fill"}
                  objectFit={"cover"}
                />
              </div>
              <div className='relative lg:flex-1'>
                <h2 className='text-3xl font-semibold leading-none tracking-tight lg:text-4xl font-display text-background'>
                  Get Connected to the Region&apos;s <br />
                  Startup & Investor Ecosystem Today!
                </h2>
                <div className='flex flex-col lg:flex-row gap-1.5 mt-5'>
                  <Button
                    onClick={() => router.push("/startups/intro")}
                    className='w-full lg:w-auto'
                  >
                    I am a Startup
                  </Button>
                  <Button
                    onClick={() => router.push("/investors/intro")}
                    variant='secondary'
                    className='w-full lg:w-auto'
                  >
                    I am an Investor
                  </Button>
                </div>
              </div>
              <div className='relative mt-5 space-y-2 lg:space-y-5 lg:w-2/5 lg:mt-0'>
                <div className='flex items-center justify-end'>
                  <span
                    ref={startupCounter}
                    className='text-3xl font-bold lg:text-5xl font-display'
                  >
                    3256+
                  </span>
                  <span className='ml-3 text-sm lg:text-base opacity-80 font-display'>
                    Startups
                  </span>
                  <div className='grid grid-cols-5 gap-1.5 ml-6'>
                    {startups.map((item, index) => (
                      <div
                        key={index}
                        className='relative overflow-hidden rounded-md h-7 lg:h-9 aspect-square'
                      >
                        <Image
                          src={item.logo_url || DummyLogo}
                          alt={""}
                          layout={"fill"}
                          objectFit={"cover"}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='flex items-center justify-end'>
                  <span
                    ref={investorCounter}
                    className='text-3xl font-bold lg:text-5xl font-display'
                  >
                    750+
                  </span>
                  <span className='ml-3 text-sm lg:text-base opacity-80 font-display'>
                    Investors
                  </span>
                  <div className='grid grid-cols-5 gap-1.5 ml-6'>
                    {investors.map((item, index) => (
                      <div
                        key={index}
                        className='relative overflow-hidden rounded-md h-7 lg:h-9 aspect-square'
                      >
                        <Image
                          src={item.logo_url || DummyLogo}
                          alt={""}
                          layout={"fill"}
                          objectFit={"cover"}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='space-y-8 lg:space-y-14'>
            <LatestNews data={latest_news} />
            <CarouselTrending title='Trending Now' data={trendings} />
            <div className='container'>
              <CarouselReport
                title='Special Reports'
                data={special_reports}
                cardHeight='h-[250px]'
                showMoreLink={`/reports`}
                showMoreText='See All Special Reports'
              />
            </div>
            <div className='container'>
              <CarouselTopVideo
                title='Videos'
                data={latest_videos}
                videoHeight='h-[180px] lg:h-[250px]'
                showMoreLink={`/videos`}
                showMoreText='See All Videos'
              />
            </div>
            <div className='container'>
              <CarouselInfobyte
                title='Infobytes'
                data={latest_infobytes}
                showMoreLink={`/infobytes`}
                showMoreText='See All Infobytes'
              />
            </div>

            <div className='container space-y-4 text-white lg:space-y-0 lg:space-x-4 lg:flex'>
              <div className='flex mb-2 lg:w-1/2 3xl:w-2/5 lg:mb-0'>
                <div className='relative w-full p-5 pt-16 lg:p-10 bg-gradient-to-tr from-purple-1 via-red-1 to-yellow-1 3xl:pt-32 lg:aspect-[4/3] 3xl:aspect-auto text-white overflow-hidden'>
                  <svg
                    className='absolute inset-0 object-cover -translate-y-20 3xl:-translate-y-56'
                    viewBox='0 0 723 542'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_3829_2406)'>
                      <mask
                        id='mask0_3829_2406'
                        style={{ maskType: "alpha" }}
                        maskUnits='userSpaceOnUse'
                        x='0'
                        y='-181'
                        width='723'
                        height='723'
                      >
                        <rect
                          y='-181'
                          width='723'
                          height='723'
                          fill='#D9D9D9'
                        />
                      </mask>
                      <g mask='url(#mask0_3829_2406)'>
                        <g filter='url(#filter0_d_3829_2406)'>
                          <circle
                            cx='178.196'
                            cy='-61.5215'
                            r='291.5'
                            transform='rotate(-120 178.196 -61.5215)'
                            fill='url(#paint0_linear_3829_2406)'
                          />
                        </g>
                        <g filter='url(#filter1_d_3829_2406)'>
                          <circle
                            cx='192.837'
                            cy='-116.163'
                            r='291.5'
                            transform='rotate(-120 192.837 -116.163)'
                            fill='url(#paint1_linear_3829_2406)'
                          />
                        </g>
                        <g filter='url(#filter2_d_3829_2406)'>
                          <circle
                            cx='216.139'
                            cy='-175.804'
                            r='291.5'
                            transform='rotate(-120 216.139 -175.804)'
                            fill='url(#paint2_linear_3829_2406)'
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <filter
                        id='filter0_d_3829_2406'
                        x='-133.352'
                        y='-371.07'
                        width='623.097'
                        height='623.097'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='2' />
                        <feGaussianBlur stdDeviation='10' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='BackgroundImageFix'
                          result='effect1_dropShadow_3829_2406'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_dropShadow_3829_2406'
                          result='shape'
                        />
                      </filter>
                      <filter
                        id='filter1_d_3829_2406'
                        x='-118.711'
                        y='-425.711'
                        width='623.097'
                        height='623.097'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='2' />
                        <feGaussianBlur stdDeviation='10' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='BackgroundImageFix'
                          result='effect1_dropShadow_3829_2406'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_dropShadow_3829_2406'
                          result='shape'
                        />
                      </filter>
                      <filter
                        id='filter2_d_3829_2406'
                        x='-95.4097'
                        y='-485.352'
                        width='623.097'
                        height='623.097'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='2' />
                        <feGaussianBlur stdDeviation='10' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='BackgroundImageFix'
                          result='effect1_dropShadow_3829_2406'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_dropShadow_3829_2406'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_3829_2406'
                        x1='232.718'
                        y1='-312.767'
                        x2='-113.304'
                        y2='-312.767'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#e14f4f' />
                        <stop offset='0.800681' stopColor='#E14F4F' />
                        <stop offset='1' stopColor='#fdaf3a' />
                      </linearGradient>
                      <linearGradient
                        id='paint1_linear_3829_2406'
                        x1='247.36'
                        y1='-367.408'
                        x2='-98.6625'
                        y2='-367.408'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#e14f4f' />
                        <stop offset='0.800681' stopColor='#E14F4F' />
                        <stop offset='1' stopColor='#fdaf3a' />
                      </linearGradient>
                      <linearGradient
                        id='paint2_linear_3829_2406'
                        x1='270.661'
                        y1='-427.049'
                        x2='-75.3612'
                        y2='-427.049'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#e14f4f' />
                        <stop offset='0.800681' stopColor='#E14F4F' />
                        <stop offset='1' stopColor='#fdaf3a' />
                      </linearGradient>
                      <clipPath id='clip0_3829_2406'>
                        <rect width='723' height='542' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className='absolute w-20 lg:w-40 top-0 -right-7 lg:-right-14 rotate-[-28deg] aspect-[199/320]'>
                    <Image
                      src={LucidMascot.src}
                      alt=''
                      layout={"fill"}
                      objectFit={"cover"}
                    />
                  </div>
                  <div className='relative flex flex-col items-start justify-end h-full'>
                    <p className='mb-5 text-3xl font-bold leading-none lg:mb-8 lg:text-5xl 2xl:text-6xl font-display'>
                      Introduce your startup to the world
                    </p>
                    <div className='flex flex-col mb-5 space-y-2 text-sm font-medium tracking-tight text-white lg:mb-8 lg:text-lg'>
                      <div className='inline-flex items-start space-x-3'>
                        <span className='inline-flex items-center justify-center flex-shrink-0 w-5 h-5 text-white border rounded-full lg:w-6 lg:h-6 bg-gradient-to-tr from-white/10 to-green-500/30 border-white/10'>
                          <Check
                            weight='bold'
                            className='text-sm lg:text-base'
                          />
                        </span>
                        <span>
                          Increase Visibility of Your Startup for Investors
                        </span>
                      </div>
                      <div className='inline-flex items-start space-x-3'>
                        <span className='inline-flex items-center justify-center flex-shrink-0 w-5 h-5 text-white border rounded-full lg:w-6 lg:h-6 bg-gradient-to-tr from-white/10 to-green-500/30 border-white/10'>
                          <Check
                            weight='bold'
                            className='text-sm lg:text-base'
                          />
                        </span>
                        <span>
                          Research Potential Investors and Send them Your Pitch
                          Deck
                        </span>
                      </div>
                      <div className='inline-flex items-start space-x-3'>
                        <span className='inline-flex items-center justify-center flex-shrink-0 w-5 h-5 text-white border rounded-full lg:w-6 lg:h-6 bg-gradient-to-tr from-white/10 to-green-500/30 border-white/10'>
                          <Check
                            weight='bold'
                            className='text-sm lg:text-base'
                          />
                        </span>
                        <span>Show up in Startup Searches</span>
                      </div>
                    </div>
                    <Button
                      onClick={handlePurchaseStartup}
                      className='w-full h-auto py-3 lg:py-3.5 text-sm lg:text-base font-medium text-left px-7'
                    >
                      Register your Startup Now
                    </Button>
                  </div>
                </div>
              </div>
              <div className='flex flex-col space-y-4 3xl:space-y-0 3xl:flex-row lg:w-1/2 3xl:flex-1'>
                <div className='relative flex flex-col items-start justify-center flex-1 p-5 overflow-hidden text-white bg-gradient-to-tr from-pink-1 to-purple-1 lg:p-12'>
                  <span className='absolute inset-y-0 right-0 z-10 w-28 bg-gradient-to-r from-transparent to-background'></span>
                  <div className='relative'>
                    <p className='text-3xl font-semibold tracking-tight lg:text-4xl font-display'>
                      Explore Investors
                    </p>
                    <div className='flex pt-4 lg:pt-7 divide-white/10'>
                      {investors.map((investor) => (
                        <a
                          key={"investor-" + investor.id}
                          href={`/investors/${investor.slug}-${investor.id}`}
                          passHref
                          className='flex items-center flex-shrink-0 w-full pr-6 mb-2 mr-2 space-x-3 duration-300 rounded-md lg:w-auto group'
                        >
                          {/* <a className='flex items-center flex-shrink-0 w-full pr-6 mb-2 mr-2 space-x-3 duration-300 rounded-md lg:w-auto group'> */}
                          <div className='flex-shrink-0 w-[40px] h-[40px]'>
                            <img
                              src={investor.logo_url}
                              className='rounded-[6px] w-14 lg:w-10'
                              alt='VC Logo'
                              width={50}
                              height={50}
                            />
                          </div>
                          <div className='flex-1 min-w-0'>
                            <p className='text-base font-medium lg:text-sm'>
                              {investor.name}
                            </p>
                            <p className='text-xs font-medium text-white/50 mt-0.5'>
                              {investor.top_sectors?.length > 0
                                ? investor.sectors
                                    .filter((s) =>
                                      investor.top_sectors.includes(s.id)
                                    )
                                    .map((item) => item.name)
                                    .join(", ")
                                : ""}
                            </p>
                          </div>
                          {/* </a> */}
                        </a>
                      ))}
                    </div>
                    <Button
                      onClick={() => router.push("/investors")}
                      variant='icon'
                      className='mt-4 text-white lg:mt-7 bg-background/30 hover:bg-background/50 group'
                    >
                      Explore All Investors
                      <ArrowRight className='ml-2 transition-transform duration-300 opacity-50 group-hover:translate-x-1' />
                    </Button>
                  </div>
                </div>
                <div className='relative flex flex-col items-start justify-center flex-1 overflow-hidden text-white p-7 lg:p-12 bg-gradient-to-br from-pink-1 to-purple-1'>
                  <div>
                    <p className='text-3xl font-semibold tracking-tight lg:text-4xl font-display'>
                      Explore Startups
                    </p>
                    <span className='absolute inset-y-0 right-0 z-10 w-28 bg-gradient-to-r from-transparent to-background'></span>
                    <div className='relative'>
                      <div className='flex pt-4 lg:pt-7 divide-white/10'>
                        {startups.map((startup) => (
                          <Link
                            legacyBehavior
                            href={`/startups/${startup.slug}-${startup.id}`}
                            passHref
                            key={"startup-" + startup.id}
                          >
                            <a className='flex items-center flex-shrink-0 w-full pr-6 mb-2 mr-2 space-x-3 duration-300 rounded-md transitio lg:w-auto group'>
                              <div className='flex-shrink-0 w-[40px] h-[40px]'>
                                <img
                                  src={startup.logo_url}
                                  className='rounded-[6px] w-14 lg:w-10'
                                  alt='Startup Logo'
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className='flex-1 min-w-0'>
                                <p className='text-base font-medium lg:text-sm'>
                                  {startup?.name}
                                </p>
                                <p className='text-xs text-white/50 mt-0.5'>
                                  {startup?.city
                                    ? `${startup.city.name}, `
                                    : ""}
                                  {startup?.country.country_name}
                                </p>
                              </div>
                            </a>
                          </Link>
                        ))}
                      </div>
                      <Button
                        onClick={() => router.push("/startups")}
                        variant='icon'
                        className='mt-4 text-white lg:mt-7 bg-background/30 hover:bg-background/50 group'
                      >
                        Explore All Startups
                        <ArrowRight className='ml-2 transition-transform duration-300 opacity-50 group-hover:translate-x-1' />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {latest_opinions?.length > 0 && (
              <div className='container'>
                <CarouselExpert
                  title='Opinions'
                  data={latest_opinions}
                  showMoreLink={`/opinions`}
                  showMoreText='See All Opinions'
                />
              </div>
            )}

            <div className='container space-y-8 lg:space-y-14'>
              <CarouselSpotlight
                title='Spotlights'
                data={latest_spotlights}
                cardHeight='h-[250px]'
                showMoreLink={`/spotlights`}
                showMoreText='See All Spotlights'
              />

              <CarouselArticle
                title='Articles'
                cardHeight='h-[250px]'
                data={latest_articles}
                showMoreLink={`/articles`}
                showMoreText='See All Articles'
              />
            </div>

            <Subscribe />
          </div>
        </main>
      </Layout>
    </>
  );
};

export async function getServerSideProps() {
  const contentTopicsData = await getTopics();
  const topics = contentTopicsData?.data || [];

  const trendingData = await getTrendingContents();
  const trendings = trendingData.data;

  const contents = await getContentsHomePage({ is_random: true });
  const banners = await getBannerContents();

  const reportData = await getContentsByContentTypeName(
    CONTENT_TYPES.SPECIAL_REPORTS
  );

  const special_reports = reportData?.data?.data || [];

  const newsData = await getContentsByContentTypeName(CONTENT_TYPES.NEWS);

  const latest_news = newsData?.data?.data || [];

  const latest_videos = contents.data[CONTENT_TYPES.VIDEOS] || [];
  const latest_articles = contents.data[CONTENT_TYPES.ARTICLES] || [];
  const latest_infobytes = contents.data[CONTENT_TYPES.INFOBYTES] || [];
  const latest_spotlights = contents.data[CONTENT_TYPES.SPOTLIGHTS] || [];
  // const latest_news = contents.data[CONTENT_TYPES.NEWS] || [];
  const latest_opinions = contents.data[CONTENT_TYPES.OPINIONS] || [];

  const special_report_loading = false;
  const video_loading = false;
  const article_loading = false;
  const loaded = true;

  return {
    props: {
      special_reports,
      special_report_loading,
      latest_videos,
      video_loading,
      latest_articles,
      article_loading,
      topics,
      latest_infobytes,
      latest_spotlights,
      trendings,
      latest_news,
      loaded,
      latest_opinions,
      banners: banners.data || [],
    },
  };
}

export default Home;
